<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-user-edit" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li
                  v-for="error in errors"
                  v-bind:key="error"
                  v-html="error"
                ></li>
              </ul>
            </div>
            <ul class="nav nav-tabs mb-2 mt-0" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-general-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-general"
                  type="button"
                  role="tab"
                  aria-selected="true"
                >
                  <i class="bi bi-person"></i>
                  {{
                    $t(
                      "Users.GeneralSettings",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="pills-custom-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-custom"
                  type="button"
                  role="tab"
                  aria-selected="false"
                >
                  <i class="bi bi-info-circle"></i>
                  {{
                    $t(
                      "Users.CustomSettings",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </li>
            </ul>
            <div class="tab-content">
              <div
                class="tab-pane fade active show"
                id="pills-general"
                role="tabpanel"
              >
                <div class="row row-cols-1">
                  <div class="col-md-2 text-center border">
                    <img
                      :src="require('@/assets/images/user/user-icon-lg.png')"
                      class="rounded img-fluid mt-3"
                      width="200"
                    />
                    <!-- <p class="mt-1">
                      <a>{{
                        $t(
                          "Users.ChangeProfile",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</a>
                    </p> -->
                  </div>
                  <div class="col-md-5">
                    <label for="FirstName" class="form-label required"
                      >{{
                        $t(
                          "Users.FirstName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="FirstName"
                      v-model="userData.firstName"
                      :autocomplete="this.$isAutoComplete"
                      :spellcheck="this.$isTextSpellCheck"
                      required
                    />
                    <br />
                    <label for="Email" class="form-label required"
                      >{{
                        $t(
                          "Users.Email",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="Email"
                      v-model="userData.email"
                      :autocomplete="this.$isAutoComplete"
                      :spellcheck="this.$isTextSpellCheck"
                      required
                    />
                    <br />
                    <label for="UserName" class="form-label"
                      >{{
                        $t(
                          "Users.UserName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="UserName"
                      v-model="userData.userName"
                      :autocomplete="this.$isAutoComplete"
                      :spellcheck="this.$isTextSpellCheck"
                    />
                  </div>
                  <div class="col col-md-5">
                    <label for="LastName" class="form-label required"
                      >{{
                        $t(
                          "Users.LastName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="LastName"
                      v-model="userData.lastName"
                      :autocomplete="this.$isAutoComplete"
                      :spellcheck="this.$isTextSpellCheck"
                      required
                    />
                    <br />
                    <label for="Degree" class="form-label"
                      >{{
                        $t(
                          "Users.Degree",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Degree"
                      v-model="userData.degree"
                      :autocomplete="this.$isAutoComplete"
                      :spellcheck="this.$isTextSpellCheck"
                    />
                    <br />
                    <label for="GSM" class="form-label"
                      >{{
                        $t(
                          "Users.GSM",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="GSM"
                      v-model="userData.mobileNumber"
                      :autocomplete="this.$isAutoComplete"
                      :spellcheck="this.$isTextSpellCheck"
                    />
                  </div>
                </div>
                <br />
                <div class="row row-cols-1">
                  <div class="col col-md-4">
                    <label for="IdentityNumber" class="form-label"
                      >{{
                        $t(
                          "Users.IdentityNumber",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="IdentityNumber"
                      v-model="userData.identityNumber"
                      :spellcheck="this.$isTextSpellCheck"
                      :autocomplete="this.$isAutoComplete"
                    />
                    <br />
                    <label for="Department" class="form-label"
                      >{{
                        $t(
                          "Users.Department",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Department"
                      v-model="userData.department"
                      :spellcheck="this.$isTextSpellCheck"
                      :autocomplete="this.$isAutoComplete"
                    />
                  </div>
                  <div class="col col-md-4">
                    <label for="BirthDate" class="form-label"
                      >{{
                        $t(
                          "Users.BirthDate",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <DatePicker
                      type="date"
                      id="BirthDate"
                      :value="userData.bornAt"
                      :displayFormat="$store.getters._dateFormat"
                      @valueChanged="onChangeBirthDate"
                    />
                    <br />
                    <label for="StartWork" class="form-label"
                      >{{
                        $t(
                          "Users.StartWork",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <DatePicker
                      type="date"
                      id="StartWork"
                      :value="userData.startedAt"
                      :displayFormat="$store.getters._dateFormat"
                      @valueChanged="onChangeStartWork"
                    />
                  </div>
                  <div class="col col-md-4">
                    <label for="Extension" class="form-label"
                      >{{
                        $t(
                          "Users.Extension",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Extension"
                      v-model="userData.extension"
                      :spellcheck="this.$isTextSpellCheck"
                      :autocomplete="this.$isAutoComplete"
                    />
                    <br />
                    <label for="DepartureWork" class="form-label"
                      >{{
                        $t(
                          "Users.DepartureWork",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <DatePicker
                      type="date"
                      id="DepartureWork"
                      :value="userData.endedAt"
                      :displayFormat="$store.getters._dateFormat"
                      @valueChanged="onChangeEndedAt"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <br />
                  <div class="form-check">
                    <label for="IsApiUser" class="form-label">{{
                      $t(
                        "Users.IsApiUser",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsApiUser"
                      v-model="userData.isApiUser"
                    />
                  </div>
                  <div class="form-check">
                    <label for="IsActive" class="form-label">{{
                      $t(
                        "BaseModelFields.IsActive",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsActive"
                      v-model="userData.isActive"
                    />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-custom" role="tabpanel">
                <div class="row row-cols-1">
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label for="UserGroups" class="form-label"
                        >{{
                          $t(
                            "Users.UserGroups",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        @onMultipleSelectChange="onChangeUserGroups"
                        :selectedData="selectedUserGroup"
                        :isMultiple="true"
                        :isParameters="true"
                        :isGatewayRequest="true"
                        :requestUrl="
                          String.format(
                            '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup'
                          )
                        "
                      />
                    </div>
                    <div class="mb-3">
                      <label for="SpecificCode1" class="form-label">{{
                        $t(
                          "Users.SpecificCode1",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="SpecificCode1"
                        v-model="userData.specificCode1"
                        :autocomplete="this.$isAutoComplete"
                        :spellcheck="this.$isTextSpellCheck"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="ActiveMenu" class="form-label"
                        >{{
                          $t(
                            "Users.ActiveMenu",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </label>
                      <FormSelect
                        :isDetailIconShow="true"
                        :routeLink="String.format('#/Menu/ListItem/')"
                        type="SelectWithRemoteDataAndInternalSearch"
                        @onChange="onChangeActiveMenu"
                        :selectedData="selectedMenu"
                        :isGatewayRequest="true"
                        requestUrl="/Lcdp-SummaryMenus"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="Level2Manager" class="form-label">{{
                        $t(
                          "Users.Level2Manager",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        :isDetailIconShow="true"
                        :routeLink="String.format('#/User/Edit/')"
                        @onChange="onChangeLevel2Manager"
                        :selectedData="selectedLevel2Manager"
                        :isParameters="true"
                        :isGatewayRequest="true"
                        :requestUrl="
                          String.format(
                            '/Lcdp-SummaryOrganizationalUnitItems?name=User'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label for="PermissionGroups" class="form-label"
                        >{{
                          $t(
                            "Users.PermissionGroups",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        @onMultipleSelectChange="onChangePermissionGroups"
                        :selectedData="selectedPermissionGroup"
                        :isMultiple="true"
                        :isParameters="true"
                        :isGatewayRequest="true"
                        :requestUrl="
                          String.format(
                            '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup'
                          )
                        "
                      />
                    </div>
                    <div class="mb-3">
                      <label for="SpecificCode2" class="form-label">{{
                        $t(
                          "Users.SpecificCode2",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="SpecificCode2"
                        v-model="userData.specificCode2"
                        :spellcheck="this.$isTextSpellCheck"
                        :autocomplete="this.$isAutoComplete"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="ActiveLanguage" class="form-label"
                        >{{
                          $t(
                            "Users.ActiveLanguage",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </label>
                      <FormSelect
                        type="SelectWithLocalData"
                        :selectedData="selectedActiveLanguage"
                        :allowEmpty="false"
                        :data="activeLanguageData"
                        @onChange="onChangeLanguage"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="Level3Manager" class="form-label">{{
                        $t(
                          "Users.Level3Manager",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        :isDetailIconShow="true"
                        :routeLink="String.format('#/User/Edit/')"
                        @onChange="onChangeLevel3Manager"
                        :selectedData="selectedLevel3Manager"
                        :isParameters="true"
                        :isGatewayRequest="true"
                        :requestUrl="
                          String.format(
                            '/Lcdp-SummaryOrganizationalUnitItems?name=User'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label for="WelcomeUrl" class="form-label"
                        >{{
                          $t(
                            "Users.WelcomeUrl",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="WelcomeUrl"
                        v-model="userData.welcomeUrl"
                        :spellcheck="this.$isTextSpellCheck"
                        :autocomplete="this.$isAutoComplete"
                        placeholder="/"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="SpecificCode3" class="form-label">{{
                        $t(
                          "Users.SpecificCode3",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="SpecificCode3"
                        v-model="userData.specificCode3"
                        :spellcheck="this.$isTextSpellCheck"
                        :autocomplete="this.$isAutoComplete"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="Level1Manager" class="form-label">{{
                        $t(
                          "Users.Level1Manager",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        :isDetailIconShow="true"
                        :routeLink="String.format('#/User/Edit/')"
                        @onChange="onChangeLevel1Manager"
                        :selectedData="selectedLevel1Manager"
                        :isParameters="true"
                        :isGatewayRequest="true"
                        :requestUrl="
                          String.format(
                            '/Lcdp-SummaryOrganizationalUnitItems?name=User'
                          )
                        "
                      />
                    </div>
                    <div class="mb-3">
                      <label for="Buddy" class="form-label">{{
                        $t(
                          "Users.Buddy",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <FormSelect
                        type="SelectWithRemoteDataAndInternalSearch"
                        :isDetailIconShow="true"
                        :routeLink="String.format('#/User/Edit/')"
                        @onChange="onChangeBuddy"
                        :selectedData="selectedBuddy"
                        :isParameters="true"
                        :isGatewayRequest="true"
                        :requestUrl="
                          String.format(
                            '/Lcdp-SummaryOrganizationalUnitItems?name=User'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ActionButtons
              classes="mt-3"
              @methodSave="onSubmit('btn-save')"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "UserEdit",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      userData: {},
      selectedActiveLanguage: [],
      selectedUserGroup: [],
      selectedPermissionGroup: [],
      selectedMenu: [],
      selectedLevel1Manager: [],
      selectedLevel2Manager: [],
      selectedLevel3Manager: [],
      selectedBuddy: [],
      // hashEmailAddress: "",
      activeLanguageData: [
        {
          key: "tr",
          value: this.$t(
            "Users.TR",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "en",
          value: this.$t(
            "Users.EN",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
    };
  },
  methods: {
    onChangeBirthDate(value) {
      this.userData.bornAt = value;
      if (!String.isNullOrWhiteSpace(value)) {
        this.userData.bornAt = this.$moment(value).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
      }
    },
    onChangeStartWork(value) {
      this.userData.startedAt = value;
      if (!String.isNullOrWhiteSpace(value)) {
        this.userData.startedAt = this.$moment(value).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
      }
    },
    onChangeEndedAt(value) {
      this.userData.endedAt = value;
      if (!String.isNullOrWhiteSpace(value)) {
        this.userData.endedAt = this.$moment(value).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
      }
    },
    onChangeLevel1Manager(selected) {
      this.userData.level1ManagerPublicId = selected.key;
    },
    onChangeLevel2Manager(selected) {
      this.userData.level2ManagerPublicId = selected.key;
    },
    onChangeLevel3Manager(selected) {
      this.userData.level3ManagerPublicId = selected.key;
    },
    onChangeBuddy(selected) {
      this.userData.buddyPublicId = selected.key;
    },
    onChangeActiveMenu(selected) {
      this.userData.ActiveMenuId = selected.key;
      this.userData.activeMenuName = selected.value;
    },
    onChangeUserGroups(ids) {
      this.userData.userGroupPublicIds = ids;
      // this.userGroupData.adminNames = selected.value;
    },
    onChangePermissionGroups(ids) {
      this.userData.permissionGroupPublicIds = ids;
      // this.userGroupData.adminNames = selected.value;
    },
    onChangeLanguage(selected) {
      this.userData.activeLanguage = selected.key;
    },
    getActiveLanguage(key) {
      var text = this.$t(
        "Users.TR",
        {},
        { locale: this.$store.state.activeLang }
      );
      if (key == "tr") {
        text = this.$t(
          "Users.TR",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (key == "en") {
        text = this.$t(
          "Users.EN",
          {},
          { locale: this.$store.state.activeLang }
        );
      }
      return text;
    },
    getUser() {
      var self = this;
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-GetUser?id={0}&isCacheValue={1}",
            this.$route.params.userId,
            false
          )
        )
        .then((response) => {
          self.userData = response.data.item;

          this.$root.preparePhoneNumber("GSM", self.userData.mobileNumber);

          if (response.data.item.permissionGroups != null) {
            var listPermissionIds = [];
            var listPermissionNames = [];

            if (response.data.item.permissionGroups.length > 0) {
              $.each(response.data.item.permissionGroups, function (i, v) {
                listPermissionIds.push(v.publicId);
                listPermissionNames.push(v.name);
              });
              self.userData.permissionGroupPublicIds = listPermissionIds.join(
                this.$systemSeparator
              );
            }

            if (listPermissionIds.length > 0) {
              $.each(listPermissionIds, function (i, v) {
                self.selectedPermissionGroup.push({
                  key: v,
                  value: listPermissionNames[i],
                });
              });
            }
          }
          if (response.data.item.userGroups != null) {
            var listUserGroupsIds = [];
            var listUserGroupsNames = [];

            if (response.data.item.userGroups.length > 0) {
              $.each(response.data.item.userGroups, function (i, v) {
                listUserGroupsIds.push(v.publicId);
                listUserGroupsNames.push(v.name);
              });
              self.userData.userGroupPublicIds = listUserGroupsIds.join(
                this.$systemSeparator
              );
            }

            if (listUserGroupsIds.length > 0) {
              $.each(listUserGroupsIds, function (i, v) {
                self.selectedUserGroup.push({
                  key: v,
                  value: listUserGroupsNames[i],
                });
              });
            }
          }
          if (!String.isNullOrWhiteSpace(response.data.item.activeLanguage)) {
            self.selectedActiveLanguage.push({
              key: response.data.item.activeLanguage.toString(),
              value: self.getActiveLanguage(response.data.item.activeLanguage),
            });
            self.userData.activeLanguage = response.data.item.activeLanguage;
          }
          if (!String.isNullOrWhiteSpace(response.data.item.activeMenuId)) {
            self.selectedMenu.push({
              key: response.data.item.activeMenuId.toString(),
              value: response.data.item.activeMenuName,
            });
            self.userData.activeMenuId = response.data.item.activeMenuId;
          }
          if (!String.isNullOrWhiteSpace(response.data.item.level1Manager)) {
            self.selectedLevel1Manager.push({
              key: response.data.item.level1Manager.toString(),
              value: response.data.item.level1ManagerName.toString(),
            });
            self.userData.level1ManagerPublicId =
              response.data.item.level1Manager;
          }
          if (!String.isNullOrWhiteSpace(response.data.item.level2Manager)) {
            self.selectedLevel2Manager.push({
              key: response.data.item.level2Manager.toString(),
              value: response.data.item.level2ManagerName.toString(),
            });
            self.userData.level2ManagerPublicId =
              response.data.item.level2Manager;
          }
          if (!String.isNullOrWhiteSpace(response.data.item.level3Manager)) {
            self.selectedLevel3Manager.push({
              key: response.data.item.level3Manager.toString(),
              value: response.data.item.level3ManagerName.toString(),
            });
            self.userData.level3ManagerPublicId =
              response.data.item.level3Manager;
          }
          if (!String.isNullOrWhiteSpace(response.data.item.buddy)) {
            self.selectedBuddy.push({
              key: response.data.item.buddy.toString(),
              value: response.data.item.buddyName.toString(),
            });
            self.userData.buddyPublicId = response.data.item.buddy;
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-user-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-EditCompanyUser", { ...this.userData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/User/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>
